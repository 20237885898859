import React from "react";
import ContentLibs from "../../../libs/ContentLibs";
import Components from "../../index";

const Shortcut = ({ ceData }) => {
  let contentElements = [];

  if (ceData.contentParts?.contents) {
    contentElements = ContentLibs.unifyContentArray(
      ceData.contentParts.contents
    );
  }

  return (
    <>
      {contentElements &&
        contentElements.map((shortcutContentItem, i) =>
          Components(shortcutContentItem, i)
        )}
    </>
  );
};

export default Shortcut;
